import Vue from 'vue'
import VueRouter from 'vue-router'
var VueScrollTo = require('vue-scrollto');

import responsiveRoute from '../mixins/RouterMixin.js';

Vue.use(VueRouter)
Vue.use(VueScrollTo)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: responsiveRoute({
          // route level code-splitting
          // this generates a separate chunk for this route
          // which is lazy-loaded when the route is visited.
          xs: () => import('../components/CarTypePickerXS.vue'),
          // sm key is missing, it falls back to xs
          sm: () => import('../components/CarTypePicker.vue')
          // lg, xl keys are missing falls back to md
      })
  },
  {
    path: '/booking_form',
    name: 'BookingForm',
    component: responsiveRoute({
          // route level code-splitting
          // this generates a separate chunk for this route
          // which is lazy-loaded when the route is visited.
          xs: () => import('../components/BookingFormXS.vue'),
          // sm key is missing, it falls back to xs
          sm: () => import('../components/BookingForm.vue')
          // lg, xl keys are missing falls back to md
      })
  },
  {
    path: '/checkout',
    name: 'CheckOut',
    component: responsiveRoute({
          // route level code-splitting
          // this generates a separate chunk for this route
          // which is lazy-loaded when the route is visited.
          xs: () => import('../components/CheckOutXS.vue'),
          // sm key is missing, it falls back to xs
          sm: () => import('../components/CheckOut.vue')
          // lg, xl keys are missing falls back to md
      })
  },
  {
    path: '/check/:order_id',
    name: 'CheckOutCheck',
    component: responsiveRoute({
          // route level code-splitting
          // this generates a separate chunk for this route
          // which is lazy-loaded when the route is visited.
          xs: () => import('../components/CheckOutCheckXS.vue'),
          // sm key is missing, it falls back to xs
          sm: () => import('../components/CheckOutCheck.vue')
          // lg, xl keys are missing falls back to md
      }),
    props: true,
  },
  {
    path: '/offer',
    name: 'JobOffer',
    component: responsiveRoute({
          // route level code-splitting
          // this generates a separate chunk for this route
          // which is lazy-loaded when the route is visited.
          xs: () => import('../components/JobOfferXS.vue'),
          // sm key is missing, it falls back to xs
          sm: () => import('../components/JobOffer.vue')
          // lg, xl keys are missing falls back to md
      })
  },
  {
    path: '/coupons',
    name: 'Coupons',
    component: responsiveRoute({
          // route level code-splitting
          // this generates a separate chunk for this route
          // which is lazy-loaded when the route is visited.
          xs: () => import('../components/CouponsXS.vue'),
          // sm key is missing, it falls back to xs
          sm: () => import('../components/Coupons.vue')
          // lg, xl keys are missing falls back to md
      })
  },
  {
    path: '/about',
    name: 'About',
    component: responsiveRoute({
          // route level code-splitting
          // this generates a separate chunk for this route
          // which is lazy-loaded when the route is visited.
          xs: () => import('../components/AboutXS.vue'),
          // sm key is missing, it falls back to xs
          sm: () => import('../components/About.vue')
          // lg, xl keys are missing falls back to md
      })
  },
  {
    path: '/terms',
    name: 'Terms',
    component: responsiveRoute({
          // route level code-splitting
          // this generates a separate chunk for this route
          // which is lazy-loaded when the route is visited.
          xs: () => import('../components/TermsXS.vue'),
          // sm key is missing, it falls back to xs
          sm: () => import('../components/Terms.vue')
          // lg, xl keys are missing falls back to md
      })
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: responsiveRoute({
          // route level code-splitting
          // this generates a separate chunk for this route
          // which is lazy-loaded when the route is visited.
          xs: () => import('../components/ImprintXS.vue'),
          // sm key is missing, it falls back to xs
          sm: () => import('../components/Imprint.vue')
          // lg, xl keys are missing falls back to md
      })
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: responsiveRoute({
          // route level code-splitting
          // this generates a separate chunk for this route
          // which is lazy-loaded when the route is visited.
          xs: () => import('../components/PrivacyXS.vue'),
          // sm key is missing, it falls back to xs
          sm: () => import('../components/Privacy.vue')
          // lg, xl keys are missing falls back to md
      })
  },
]

const router = new VueRouter({

  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash
        // , offset: { x: 0, y: 10 }
      }
    }else{
      return { x: 0, y: 0 };
    }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
    if (to.hash && to.path != from.path){
        Vue.nextTick().then(() => setTimeout(() => {VueScrollTo.scrollTo(to.hash, 700) }, 500));
    }
});

export default router
