import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  // state is the same as what would typically go inside of the data object when using Vue without Vuex.
  state: {
    items: {
        'compact': {
            'premium': {
                'services': [
                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Autowäsche',
                    'Felgenreinigung',
                    'Armaturen Reinigung und Pflege',
                    'Lüftungsschlitze reinigen',
                    'Teppichreinigung',
                    'Fußmattenreinigung',
                    'Sitzschienenreinigung',
                    'Polster- /Lederreinigung',
                    'Hartwachsversiegelung',
                    'Lederpflege',
                    'Reifenpflege',
                    'Fensterreinigung'
                ],
                'price': 259,
                'duration': 300,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 99, 'duration': 90},
                    'Mattlack-Nanoversiegelung': {'price': 99, 'duration': 90},
                    'Keramikversiegelung': {'price': 199, 'duration': 150},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'duration': 150},
                    'Himmelreinigung': {'price': 29, 'duration': 60},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 49, 'disabled': 'disabled', 'duration': 0},
                }
            },
            'comfort': {
                'services': [
                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Autowäsche',
                    'Felgenreinigung',
                    'Armaturen Reinigung und Pflege',
                    'Lüftungsschlitze reinigen',
                    'Teppichreinigung',
                    'Fußmattenreinigung',
                    'Sitzschienenreinigung',
                    'Polster- /Lederreinigung',
                    'Lackversiegelung',
                    'Reifenpflege',
                    'Fensterreinigung'
                ],
                'price': 229,
                'duration': 180,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 99, 'duration': 90},
                    'Mattlack-Nanoversiegelung': {'price': 99, 'duration': 90},
                    'Keramikversiegelung': {'price': 199, 'duration': 150},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'duration': 150},
                    'Himmelreinigung': {'price': 29, 'duration': 60},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 49, 'duration': 30},
                }
            },
            'inside':  {
                'services': [
                    'Autowäsche',
                    'Armaturen Reinigung und Pflege',
                    'Lüftungsschlitze reinigen',
                    'Teppichreinigung',
                    'Fußmattenreinigung',
                    'Sitzschienenreinigung',
                    'Polster- /Lederreinigung',
                    'Fensterreinigung'
                ],
                'price': 199,
                'duration': 180,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 99, 'disabled': 'disabled', 'duration': 0},
                    'Mattlack-Nanoversiegelung': {'price': 99, 'disabled': 'disabled', 'duration': 0},
                    'Keramikversiegelung': {'price': 199, 'disabled': 'disabled', 'duration': 0},
                    'Felgenversiegelung': {'price': 70, 'disabled': 'disabled', 'duration': 0},
                    'Ozonbehandlung': {'price': 40, 'duration': 150},
                    'Himmelreinigung': {'price': 29, 'duration': 60},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 49, 'duration': 30},
                }
            },
            'outside':  {
                'services': [
                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Felgenreinigung',
                    'Autowäsche',
                    'Lackversiegelung',
                    'Fensterreinigung',
                    'Reifenpflege'
                ],
                'price': 209,
                'duration': 120,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 99, 'duration': 90},
                    'Mattlack-Nanoversiegelung': {'price': 99, 'duration': 90},
                    'Keramikversiegelung': {'price': 199, 'duration': 150},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'disabled': 'disabled', 'duration': 0},
                    'Himmelreinigung': {'price': 29, 'disabled': 'disabled', 'duration': 0},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 49, 'disabled': 'disabled', 'duration': 0},
                }
            },
            'winter':  {
                'services': ['Elaskon Unterbodenschutz',
                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Felgenreinigung',
                    'Autowäsche',
                    'Hartwachsversiegelung',
                    'Fensterreinigung',
                    'Reifenpflege',
                ],
                'price': 249,
                'duration': 420,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 99, 'duration': 90},
                    'Mattlack-Nanoversiegelung': {'price': 99, 'duration': 90},
                    'Keramikversiegelung': {'price': 199, 'duration': 150},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'disabled': 'disabled', 'duration': 0},
                    'Himmelreinigung': {'price': 29, 'disabled': 'disabled', 'duration': 0},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 49, 'disabled': 'disabled', 'duration': 0},
                }
            },
            'fresh': {
                'services': [],
                'price': 49,
                'duration': 60,
                'additional_service': {}
            },
            'delivery': {
                'services': [],
                'price': 39,
                'duration': 120,
                'additional_service': {}
            },
            'gw': {
                'services': [],
                'price': 39,
                'duration': 240,
                'additional_service': {}
            },
        },
        'combi': {
            'premium': {
                'services': [

                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Autowäsche',
                    'Felgenreinigung',
                    'Armaturen Reinigung und Pflege',
                    'Lüftungsschlitze reinigen',
                    'Teppichreinigung',
                    'Fußmattenreinigung',
                    'Sitzschienenreinigung',
                    'Polster- /Lederreinigung',
                    'Hartwachsversiegelung',
                    'Lederpflege',
                    'Reifenpflege',
                    'Fensterreinigung'
                ],
                'price': 289,
                'duration': 360,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 119, 'duration': 120},
                    'Mattlack-Nanoversiegelung': {'price': 119, 'duration': 120},
                    'Keramikversiegelung': {'price': 219, 'duration': 180},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'duration': 150},
                    'Himmelreinigung': {'price': 39, 'duration': 60},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 59, 'disabled': 'disabled', 'duration': 0},
                }
            },
            'comfort': {
                'services': [
                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Autowäsche',
                    'Felgenreinigung',
                    'Armaturen Reinigung und Pflege',
                    'Lüftungsschlitze reinigen',
                    'Teppichreinigung',
                    'Fußmattenreinigung',
                    'Sitzschienenreinigung',
                    'Polster- /Lederreinigung',
                    'Lackversiegelung',
                    'Reifenpflege',
                    'Fensterreinigung'
                ],
                'price': 249,
                'duration': 300,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 119, 'duration': 120},
                    'Mattlack-Nanoversiegelung': {'price': 119, 'duration': 120},
                    'Keramikversiegelung': {'price': 219, 'duration': 180},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'duration': 150},
                    'Himmelreinigung': {'price': 39, 'duration': 60},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 59, 'duration': 30},
                }
            },
            'inside':  {
                'services': [
                    'Autowäsche',
                    'Armaturen Reinigung und Pflege',
                    'Lüftungsschlitze reinigen',
                    'Teppichreinigung',
                    'Fußmattenreinigung',
                    'Sitzschienenreinigung',
                    'Polster- /Lederreinigung',
                    'Fensterreinigung'
                ],
                'price': 219,
                'duration': 240,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 119, 'disabled': 'disabled', 'duration': 0},
                    'Mattlack-Nanoversiegelung': {'price': 119, 'disabled': 'disabled', 'duration': 0},
                    'Keramikversiegelung': {'price': 219, 'disabled': 'disabled', 'duration': 0},
                    'Felgenversiegelung': {'price': 70, 'disabled': 'disabled', 'duration': 0},
                    'Ozonbehandlung': {'price': 40, 'duration': 150},
                    'Himmelreinigung': {'price': 39, 'duration': 60},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 59, 'duration': 30},
                }
            },
            'outside':  {
                'services': [
                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Felgenreinigung',
                    'Autowäsche',
                    'Lackversiegelung',
                    'Fensterreinigung',
                    'Reifenpflege'
                ],
                'price': 229,
                'duration': 180,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 119, 'duration': 120},
                    'Mattlack-Nanoversiegelung': {'price': 119, 'duration': 120},
                    'Keramikversiegelung': {'price': 219, 'duration': 180},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'disabled': 'disabled', 'duration': 0},
                    'Himmelreinigung': {'price': 39, 'disabled': 'disabled', 'duration': 0},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 59, 'disabled': 'disabled', 'duration': 0},
                }
            },
            'winter':  {
                'services': ['Elaskon Unterbodenschutz',
                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Felgenreinigung',
                    'Autowäsche',
                    'Hartwachsversiegelung',
                    'Fensterreinigung',
                    'Reifenpflege',
                ],
                'price': 259,
                'duration': 420,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 119, 'duration': 120},
                    'Mattlack-Nanoversiegelung': {'price': 119, 'duration': 120},
                    'Keramikversiegelung': {'price': 219, 'duration': 180},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'disabled': 'disabled', 'duration': 0},
                    'Himmelreinigung': {'price': 39, 'disabled': 'disabled', 'duration': 0},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 59, 'disabled': 'disabled', 'duration': 0},
                }
            },
            'fresh': {
                'services': [],
                'price': 49,
                'duration': 60,
                'additional_service': {}
            },
            'delivery': {
                'services': [],
                'price': 39,
                'duration': 120,
                'additional_service': {}
            },
            'gw': {
                'services': [],
                'price': 39,
                'duration': 240,
                'additional_service': {}
            },
        },
        'suv': {
            'premium': {
                'services': [

                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Autowäsche',
                    'Felgenreinigung',
                    'Armaturen Reinigung und Pflege',
                    'Lüftungsschlitze reinigen',
                    'Teppichreinigung',
                    'Fußmattenreinigung',
                    'Sitzschienenreinigung',
                    'Polster- /Lederreinigung',
                    'Hartwachsversiegelung',
                    'Lederpflege',
                    'Reifenpflege',
                    'Fensterreinigung'
                ],
                'price': 319,
                'duration': 360,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 139, 'duration': 120},
                    'Mattlack-Nanoversiegelung': {'price': 139, 'duration': 120},
                    'Keramikversiegelung': {'price': 239, 'duration': 180},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'duration': 150},
                    'Himmelreinigung': {'price': 49, 'duration': 60},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 69, 'disabled': 'disabled', 'duration': 0},
                }
            },
            'comfort': {
                'services': [
                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Autowäsche',
                    'Felgenreinigung',
                    'Armaturen Reinigung und Pflege',
                    'Lüftungsschlitze reinigen',
                    'Teppichreinigung',
                    'Fußmattenreinigung',
                    'Sitzschienenreinigung',
                    'Polster- /Lederreinigung',
                    'Lackversiegelung',
                    'Reifenpflege',
                    'Fensterreinigung'
                ],
                'price': 259,
                'duration': 300,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 139, 'duration': 120},
                    'Mattlack-Nanoversiegelung': {'price': 139, 'duration': 120},
                    'Keramikversiegelung': {'price': 239, 'duration': 180},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'duration': 150},
                    'Himmelreinigung': {'price': 49, 'duration': 60},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 69, 'duration': 30},
                }
            },
            'inside':  {
                'services': [
                    'Autowäsche',
                    'Armaturen Reinigung und Pflege',
                    'Lüftungsschlitze reinigen',
                    'Teppichreinigung',
                    'Fußmattenreinigung',
                    'Sitzschienenreinigung',
                    'Polster- /Lederreinigung',
                    'Fensterreinigung'
                ],
                'price': 229,
                'duration': 300,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 139, 'disabled': 'disabled', 'duration': 0},
                    'Mattlack-Nanoversiegelung': {'price': 139, 'disabled': 'disabled', 'duration': 0},
                    'Keramikversiegelung': {'price': 239, 'disabled': 'disabled', 'duration': 0},
                    'Felgenversiegelung': {'price': 70, 'disabled': 'disabled', 'duration': 0},
                    'Ozonbehandlung': {'price': 40, 'duration': 150},
                    'Himmelreinigung': {'price': 49, 'duration': 60},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 69, 'duration': 30},
                }
            },
            'outside':  {
                'services': [
                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Felgenreinigung',
                    'Autowäsche',
                    'Lackversiegelung',
                    'Fensterreinigung',
                    'Reifenpflege'
                ],
                'price': 239,
                'duration': 240,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 139, 'duration': 120},
                    'Mattlack-Nanoversiegelung': {'price': 139, 'duration': 120},
                    'Keramikversiegelung': {'price': 239, 'duration': 180},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'disabled': 'disabled', 'duration': 0},
                    'Himmelreinigung': {'price': 49, 'disabled': 'disabled', 'duration': 0},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 69, 'disabled': 'disabled', 'duration': 0},
                }
            },
            'winter':  {
                'services': ['Elaskon Unterbodenschutz',
                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Felgenreinigung',
                    'Autowäsche',
                    'Hartwachsversiegelung',
                    'Fensterreinigung',
                    'Reifenpflege',
                ],
                'price': 269,
                'duration': 420,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 139, 'duration': 120},
                    'Mattlack-Nanoversiegelung': {'price': 139, 'duration': 120},
                    'Keramikversiegelung': {'price': 239, 'duration': 180},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'disabled': 'disabled', 'duration': 0},
                    'Himmelreinigung': {'price': 49, 'disabled': 'disabled', 'duration': 0},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 69, 'disabled': 'disabled', 'duration': 0},
                }
            },
            'fresh': {
                'services': [],
                'price': 49,
                'duration': 60,
                'additional_service': {}
            },
            'delivery': {
                'services': [],
                'price': 39,
                'duration': 120,
                'additional_service': {}
            },
            'gw': {
                'services': [],
                'price': 39,
                'duration': 240,
                'additional_service': {}
            },
        },
        'bus': {
            'premium': {
                'services': [

                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Autowäsche',
                    'Felgenreinigung',
                    'Armaturen Reinigung und Pflege',
                    'Lüftungsschlitze reinigen',
                    'Teppichreinigung',
                    'Fußmattenreinigung',
                    'Sitzschienenreinigung',
                    'Polster- /Lederreinigung',
                    'Hartwachsversiegelung',
                    'Lederpflege',
                    'Reifenpflege',
                    'Fensterreinigung'
                ],
                'price': 349,
                'duration': 360,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 159, 'duration': 180},
                    'Mattlack-Nanoversiegelung': {'price': 159, 'duration': 180},
                    'Keramikversiegelung': {'price': 259, 'duration': 240},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'duration': 150},
                    'Himmelreinigung': {'price': 59, 'duration': 60},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 79, 'disabled': 'disabled', 'duration': 0},
                }
            },
            'comfort': {
                'services': [
                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Autowäsche',
                    'Felgenreinigung',
                    'Armaturen Reinigung und Pflege',
                    'Lüftungsschlitze reinigen',
                    'Teppichreinigung',
                    'Fußmattenreinigung',
                    'Sitzschienenreinigung',
                    'Polster- /Lederreinigung',
                    'Lackversiegelung',
                    'Reifenpflege',
                    'Fensterreinigung'
                ],
                'price': 289,
                'duration': 360,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 159, 'duration': 180},
                    'Mattlack-Nanoversiegelung': {'price': 159, 'duration': 180},
                    'Keramikversiegelung': {'price': 259, 'duration': 240},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'duration': 150},
                    'Himmelreinigung': {'price': 59, 'duration': 60},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 79, 'duration': 30},
                }
            },
            'inside':  {
                'services': [
                    'Autowäsche',
                    'Armaturen Reinigung und Pflege',
                    'Lüftungsschlitze reinigen',
                    'Teppichreinigung',
                    'Fußmattenreinigung',
                    'Sitzschienenreinigung',
                    'Polster- /Lederreinigung',
                    'Fensterreinigung'
                ],
                'price': 239,
                'duration': 360,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 159, 'disabled': 'disabled', 'duration': 0},
                    'Mattlack-Nanoversiegelung': {'price': 159, 'disabled': 'disabled', 'duration': 0},
                    'Keramikversiegelung': {'price': 259, 'disabled': 'disabled', 'duration': 0},
                    'Felgenversiegelung': {'price': 70, 'disabled': 'disabled', 'duration': 0},
                    'Ozonbehandlung': {'price': 40, 'duration': 150},
                    'Himmelreinigung': {'price': 59, 'duration': 60},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 79, 'duration': 30},
                }
            },
            'outside':  {
                'services': [
                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Felgenreinigung',
                    'Autowäsche',
                    'Lackversiegelung',
                    'Fensterreinigung',
                    'Reifenpflege'
                ],
                'price': 259,
                'duration': 270,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 159, 'duration': 180},
                    'Mattlack-Nanoversiegelung': {'price': 159, 'duration': 180},
                    'Keramikversiegelung': {'price': 259, 'duration': 240},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'disabled': 'disabled', 'duration': 0},
                    'Himmelreinigung': {'price': 59, 'disabled': 'disabled', 'duration': 0},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 79, 'disabled': 'disabled', 'duration': 0},
                }
            },
            'winter':  {
                'services': [
                    'Elaskon Unterbodenschutz',
                    'Intensive Vorwäsche',
                    'Teer-/ Flugrostentfernung',
                    'Felgenreinigung',
                    'Autowäsche',
                    'Hartwachsversiegelung',
                    'Fensterreinigung',
                    'Reifenpflege',
                ],
                'price': 309,
                'duration': 420,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 159, 'duration': 180},
                    'Mattlack-Nanoversiegelung': {'price': 159, 'duration': 180},
                    'Keramikversiegelung': {'price': 259, 'duration': 240},
                    'Felgenversiegelung': {'price': 70, 'duration': 30},
                    'Ozonbehandlung': {'price': 40, 'disabled': 'disabled', 'duration': 0},
                    'Himmelreinigung': {'price': 59, 'disabled': 'disabled', 'duration': 0},
                    'Motorwäsche': {'price': 40, 'duration': 20},
                    'Lederpflege': {'price': 79, 'disabled': 'disabled', 'duration': 0},
                }
            },
            'fresh': {
                'services': [],
                'price': 49,
                'duration': 60,
                'additional_service': {}
            },
            'delivery': {
                'services': [],
                'price': 39,
                'duration': 120,
                'additional_service': {}
            },
            'gw': {
                'services': [],
                'price': 39,
                'duration': 240,
                'additional_service': {}
            },
        },
        'bike': {
            'premium': {
                'services': [
                    'Handvorwäsche',
                    'Felgenreinigung',
                    'Teerentfernung',
                    'Insektenentfernung',
                    'Windschildreinigung',
                    'Reinigung Ablagefächer inkl. Fach unter der Sitzbank',
                    'Polierung und Versiegelung Chrom & Lackteile',
                    'Reinigung und Versiegelung Kunststoff & Gummiteile'
                ],
                'price': 209,
                'duration': 180,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 99, 'disabled': 'disabled', 'duration': 0},
                    'Mattlack-Nanoversiegelung': {'price': 99, 'disabled': 'disabled', 'duration': 0},
                    'Keramikversiegelung': {'price': 199, 'disabled': 'disabled', 'duration': 0},
                    'Felgenversiegelung': {'price': 70, 'disabled': 'disabled', 'duration': 0},
                    'Ozonbehandlung': {'price': 40, 'disabled': 'disabled', 'duration': 0},
                    'Himmelreinigung': {'price': 29, 'disabled': 'disabled', 'duration': 0},
                    'Motorwäsche': {'price': 40, 'disabled': 'disabled', 'duration': 20},
                    'Lederpflege': {'price': 49, 'disabled': 'disabled', 'duration': 0},
                }
            },
            'basis': {
                'services': [
                    'Handvorwäsche',
                    'Insektenentfernung',
                    'Felgenreinigung',
                    'Windschildreinigung',
                    'Glanztrocknung mit Wachs'
                ],
                'price':59,
                'duration': 120,
                'additional_service': {
                    'Mietwagen ': {'price': 29.90, 'duration': 0},
                    'Hol- & Bring-Service': {'price': 69.00, 'duration': 0},
                    'Nanoversiegelung': {'price': 99, 'disabled': 'disabled', 'duration': 0},
                    'Mattlack-Nanoversiegelung': {'price': 99, 'disabled': 'disabled', 'duration': 0},
                    'Keramikversiegelung': {'price': 199, 'disabled': 'disabled', 'duration': 0},
                    'Felgenversiegelung': {'price': 70, 'disabled': 'disabled', 'duration': 0},
                    'Ozonbehandlung': {'price': 40, 'disabled': 'disabled', 'duration': 0},
                    'Himmelreinigung': {'price': 29, 'disabled': 'disabled', 'duration': 0},
                    'Motorwäsche': {'price': 40, 'disabled': 'disabled', 'duration': 20},
                    'Lederpflege': {'price': 49, 'disabled': 'disabled', 'duration': 0},
                }
            },
            'fresh': {
                'services': [],
                'price': 49,
                'duration': 60,
                'additional_service': {}
            },
            'delivery': {
                'services': [],
                'price': 39,
                'duration': 120,
                'additional_service': {}
            },
            'gw': {
                'services': [],
                'price': 39,
                'duration': 240,
                'additional_service': {}
            },
        },
    },
    cart: {},
    user_data: {"type": "addToUserData", "user_data": {
        'title': '',
        'lastname': '',
        'firstname': '',
        'street': '',
        'zipcode': '',
        'city': '',
        'phone': '',
        'mail': '',
        'customer_type': 'privat',
        'company': '',
        'company_tax_number': '',
    }},
    user_shipping_data: {"type": "addToUserShippingData", "user_shipping_data": {
        'company': '',
        'title': '',
        'lastname': '',
        'firstname': '',
        'street': '',
        'zipcode': '',
        'city': '',
        'phone': '',
        'mail': '',
    }},
    order_id: { "type": "addToOrderData", "order_id": ""},
    bookedDates: {},
    googleReviews: {},
    error: false
  },
  // getters are Vuex's equivalent to computed properties in Vue.
  // functions here will always contain state as a parameter
  getters: {
      car_type: state => { return state.cart.car_type},
      cleaning_type: state => { return state.cart.cleaning_type},
      cleaning: state => { return state.items[state.cart.car_type]},
      additional_service: state => { return state.cart.additional_service},
      bookedDates: state => { return state.bookedDates},
      googleReviews: state => { return state.googleReviews},
  },
  // mutations are essentially functions that update state in some way.
  // You can think of these as kind of being Vuex's equivalent to Vue's methods.
    mutations: {
        initialise(state) {

            if(document.cookie.indexOf('XSRF-TOKEN=') === -1){
                axios.get('https://api.opel-dresden.de/api/csrf-cookie');
            }

            if (sessionStorage.getItem('userData')) {
                state.user_data = JSON.parse(sessionStorage.getItem('userData'));
            }
            if (sessionStorage.getItem('userData')) {
                state.user_data = JSON.parse(sessionStorage.getItem('userData'));
            }
            if (sessionStorage.getItem('userShippingData')) {
                state.user_shipping_data = JSON.parse(sessionStorage.getItem('userShippingData'));
            }
            if (sessionStorage.getItem('cartType')) {
                state.user_data = JSON.parse(sessionStorage.getItem('cartType'));
            }
            if (sessionStorage.getItem('userCart')) {
                state.cart = JSON.parse(sessionStorage.getItem('userCart'));
            }
            if (!sessionStorage.getItem('bookedDates')) {
                axios
                    .post(`https://api.opel-dresden.de/api/booked_dates`)
                    .then(response => {
                        sessionStorage.setItem('bookedDates', JSON.stringify(response.data.data))
                        state.bookedDates = response.data.data;
                })
                .catch(function(error) {
                    console.log(error);
                });
            }else{
                state.bookedDates = JSON.parse(sessionStorage.getItem('bookedDates'));
            }
            if (!sessionStorage.getItem('googleReviews')) {
                axios
                    .post(`https://api.opel-dresden.de/api/google/reviews`)
                    .then(response => {
                        sessionStorage.setItem('googleReviews', JSON.stringify(response.data.data))
                        state.googleReviews = response.data.data;
                })
                .catch(function(error) {
                    console.log(error);
                });
            }else{
                state.googleReviews = JSON.parse(sessionStorage.getItem('googleReviews'));
            }
        },
        addToCart(state, payload) {
            sessionStorage.setItem("userCart", JSON.stringify(payload));
            return state.cart = payload;
        },
        addToUserData(state, payload) {
            sessionStorage.setItem("userData", JSON.stringify(payload));
            return state.user_data = payload;
        },
        addToUserShippingData(state, payload) {
            sessionStorage.setItem("userShippingData", JSON.stringify(payload));
            return state.user_shipping_data = payload;
        },
        addToOrderData(state, payload) {
            return state.order_id = payload;
        },
        setError(state, payload) {
            if(payload.error === true){
                return state.error = true;
            }else{
                return state.error = false;
            }

        },
    },
  // actions are effectively the functions that get called by your components in order to trigger a mutation.
  actions: {
      // add(context) {
      //     context.commit('add')
      // }
  }
})