import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
var VueScrollTo = require('vue-scrollto');
import Vuelidate from 'vuelidate'


import { PopoverPlugin, AlertPlugin, LayoutPlugin, NavbarPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

require('./styles/main.scss');



Vue.use(PopoverPlugin);
Vue.use(AlertPlugin);
Vue.use(LayoutPlugin, {
    breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
});
Vue.use(NavbarPlugin);
Vue.use(VueScrollTo);
Vue.use(Vuelidate);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  beforeCreate() { this.$store.commit('initialise') },
  render: h => h(App)
}).$mount('#app')
